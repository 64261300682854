import { qs } from './../utils';

// https://www.npmjs.com/package/js-datepicker -
// js-datepicker
// check ie11!!

import 'intl-tel-input';

var $ = require('jquery');

if (qs('#amlId')) {
    const FORM = {
        formId: '#aml',
        q: {
            qPEP: {
                wrapper: '#qPEP-wrapper',
                a: '#qPEP_a',
                b: '#qPEP_b',
                a_txt: '#qPEP_a_txt',
            },
            qTITOLARE: {
                wrapper: '#qTITOLARE-wrapper',
                a: '#qTITOLARE_a',
                b: '#qTITOLARE_b',
                b_txt: '#qTITOLARE_b_txt',
                c: '#qTITOLARE_c',
                c_txt: '#qTITOLARE_c_txt',
            },
            qLEGAME: {
                wrapper: '#qLEGAME-wrapper',
                select: '#qLEGAME',
                opt_c: 'qLEGAME_c',
                c_txt: '#qLEGAME_c_txt',
                triggerValue: 'C',
            },
            qATTIVITA_PREVALENTE: {
                wrapper: '#qATTIVITA_PREVALENTE-wrapper',
                select: '#qATTIVITA_PREVALENTE',
                triggerValue: 'B',
            },
            qALBO: {
                wrapper: '#qALBO-wrapper',
                a: '#qALBO_a',
                b: '#qALBO_b',
                a_txt: '#qALBO_a_txt',
            },
            qATTIVITA: {
                section: '#attivita-section',
                wrapper: '#qATTIVITA-wrapper',
                select: '#qATTIVITA',
                al_txt: '#qATTIVITA_al_txt',
            },
            qATTIVITA_SETTORE: {
                wrapper: '#qATTIVITA_SETTORE-wrapper',
                select: '#qATTIVITA_SETTORE',
            },
            qCARICHE: {
                wrapper: '#qCARICHE-wrapper',
                a: '#qCARICHE_a',
                b: '#qCARICHE_b',
                section: '#cariche-section',
            },
        },
    };

    var PARSLEY_CONFIG = {
        errorClass: 'has-error',
        classHandler: function(el) {
            return el.$element.parent();
        },

        errorsContainer: function(el) {
            var elems = new Set([
                'pep_q1_txt',
                'pep_q4_snd_choice_txt',
                'pep_q4_trd_choice_txt',
            ]);
            if (el.$element.attr('id') in elems) return el;
            return el.$element.closest('div');
        },
        errorsWrapper: "<span class='help-block'></span>",
        //Hack color
        errorTemplate: '<span></span>',
    };
    // if (typeof dataLayer !== 'undefined') {
    //     dataLayer.push({
    //         event: 'registrazione',
    //         eventCategory: 'Onboarding',
    //         eventAction: 'AML',
    //     });
    // }

    const targetShow = function(target, clean = true) {
        target.show();
        if (target.is('input')) {
            $(target)
                .removeAttr('disabled')
                .prop('disabled', false)
                .attr('required', '');
        } else {
            target.find('input').each(function() {
                // if (this.hasAttribute('disabled')) {
                let child = '#' + this.id;
                // console.log(this)
                $(child)
                    .removeAttr('disabled')
                    .prop('disabled', false)
                    .attr('required', '');
                //$(child).val('')
            });
        }
    };

    const targetHide = function(target, clean = true) {
        target.hide();
        if (target.is('input')) {
            $(target)
                .removeAttr('required')
                .prop('disabled', true)
                .attr('disabled');
            if (clean) {
                $(target).val('');
            }
            $(target)
                .parsley()
                .reset();
        } else {
            target.find('input').each(function() {
                let child = '#' + this.id;
                $(child)
                    .removeAttr('required')
                    .prop('disabled', true)
                    .attr('disabled');
                //$(child).val("")
                $(child)
                    .parsley()
                    .reset();
                // console.log(this, child, '#' + this.id)
            });
            if (clean) {
                target.find('select').each(function() {
                    let child = '#' + this.id;
                    $(child)
                        .val('')
                        .selectpicker('refresh');
                    $(child).removeAttr('required');
                });
            }
        }
    };
    // checkDataTargetTrigger
    const checkDataTarget = eTarget =>
        $('#' + eTarget)[0].hasAttribute('data-trigger');
    // getDataTarget(dTarget, action)
    const getDataTarget = eTarget => {
        return $('#' + eTarget)
            .closest('.aml__form__fields__input')
            .data('target');
    };
    // getDataValue()
    const getDataValue = eTarget => {
        return $('#' + eTarget)
            .closest('.aml__form__fields__input')
            .data('kval');
    };

    const getDataAction = eTarget => {
        return checkDataTarget(eTarget);
    };

    const getDataRisk = eRisk => {
        return typeof eRisk !== 'undefined' || eRisk !== null
            ? parseInt(eRisk)
            : 0;
    };

    const getDataTitle = eTitle => {
        // return $(eTitle).closest('.aml__form__fields__input').find('h4').text().split("-")[0].trim().substr(0, 28) || eTitle;
        return (
            $(eTitle)
                .closest('.aml__form__fields__input')
                .find('h4')
                .attr('data-question') || eTitle
        );
    };

    const fillDATA_RISK = function(dTitle, dRisk) {
        dRisk = isNaN(getDataRisk(dRisk)) ? 0 : getDataRisk(dRisk);
        return (DATA_RISK[dTitle] = dRisk);
    };

    const sumRisk = function() {
        let arr = Object.values(DATA_RISK);

        const arrSum = arr => arr.reduce((a, b) => a + b, 0);
        return arrSum(arr);
    };

    const appendElementToList = function() {
        let ul = document.getElementById('elementsList');
        let resultSum = document.getElementById('resultSum');

        let sumBold = document.createElement('b');
        if (ul === null) {
            return;
        }
        ul.innerHTML = '';
        resultSum.innerHTML = '';

        for (let k in DATA_RISK) {
            let li = document.createElement('li');
            let bold = document.createElement('b');
            var title = k;
            if (k == 'ang_district_risk') {
                title = 'Rischio Città (' + ADDITIONAL_DATA['city'] + ')';
            } else if (k == 'ang_country_risk') {
                title = 'Rischio Nazione';
            }

            li.appendChild(document.createTextNode(title + ': '));
            li.appendChild(bold);
            bold.appendChild(document.createTextNode(+DATA_RISK[k]));
            ul.appendChild(li);
        }
        sumBold.appendChild(document.createTextNode(sumRisk()));

        resultSum.appendChild(sumBold);
    };

    const dTargetToggle = function(eTarget, clean = true) {
        let dTarget;
        let activeState;
        let dRisk;
        let dTitle;
        if (eTarget.target) {
            dTarget = $('#' + getDataTarget(eTarget.target.id));
            activeState = getDataAction(eTarget.target.id);
            //RISK in target
            dRisk = getDataRisk(eTarget.target.dataset.risk);
            dTitle = getDataTitle(eTarget.target);
        } else {
            eTarget = eTarget[0];
            dTarget = $('#' + getDataTarget(eTarget.id));
            activeState = getDataAction(eTarget.id);
            //RISK in target
            dRisk = getDataRisk(eTarget.dataset.risk);
            dTitle = getDataTitle(eTarget);
        }

        fillDATA_RISK(dTitle, dRisk);
        if (!activeState) {
            targetHide(dTarget, clean);
        } else {
            targetShow(dTarget, clean);
        }
    };

    const selectToggle = function(eTarget, clean = true) {
        let dTarget = $('#' + getDataTarget(eTarget.target.id));
        let dTriggerValue = getDataValue(eTarget.target.id);
        let eValue = eTarget.target.value;
        // console.log(eTarget.target.id, dTarget, dTriggerValue, eValue)
        //RISK in target's options
        if (eTarget.target.selectedOptions[0]) {
            let dRisk = getDataRisk(
                eTarget.target.selectedOptions[0].getAttribute('data-risk')
            );
            dRisk = isNaN(dRisk) ? 0 : dRisk;
            let dTitle = getDataTitle('#' + eTarget.target.id);
            fillDATA_RISK(dTitle, dRisk);
            if (eValue !== dTriggerValue) {
                targetHide(dTarget, clean);
            } else {
                targetShow(dTarget, clean);
            }
        }

        //se qATTIVITA_PREVALENTE con trigger per Lavoratore Autonomo,
        // nascondiamo select qATTIVITA_SETTORE
        if ('#' + eTarget.target.id === FORM.q.qATTIVITA_PREVALENTE.select) {
            if (eValue === dTriggerValue) {
                $(FORM.q.qATTIVITA_SETTORE.wrapper).hide();
                if (clean) {
                    $('#qATTIVITA_SETTORE').val('');
                }
                $('#qATTIVITA_SETTORE').removeAttr('required');
            } else {
                $(FORM.q.qATTIVITA_SETTORE.wrapper).show();
                if (clean) {
                    $('#qATTIVITA_SETTORE').val('');
                }
                $('qATTIVITA_SETTORE').prop('required', true);
            }
        }
        //se qATTIVITA con trigger per  U-altre o Z-altro,
        // mostriamo txt target
        if ('#' + eTarget.target.id === FORM.q.qATTIVITA.select) {
            //U or Z
            if (eValue === 'U' || eValue === 'Z') {
                // or Z
                targetShow(dTarget);
            } else {
                targetHide(dTarget);
            }
        }
        //console.log('DATA_RISK', DATA_RISK);
        //console.log('sumRisk', sumRisk());
    };

    $(document).ready(function() {
        appendElementToList();

        if ($('#qALBO_a').is(':checked')) {
            dTargetToggle($('#qALBO_a'), false);
            appendElementToList();
        }

        if ($('#qCARICHE_a').is(':checked')) {
            dTargetToggle($('#qCARICHE_a'), false);
            appendElementToList();
        }

        if ($('#qPEP_a').is(':checked')) {
            dTargetToggle($('#qPEP_a'), false);
            appendElementToList();
        }

        if ($('#qTITOLARE_c').is(':checked')) {
            dTargetToggle($('#qTITOLARE_c'), false);
            appendElementToList();
        }

        jQuery('input[type=radio]').on('change', function(e) {
            dTargetToggle(e);
            appendElementToList();
        });

        $('#mobile-phone').intlTelInput({ initialCountry: 'it' });

        var beginning = true;
        jQuery('select').on('change', function(e) {
            let skip = $('#' + e.target.id).attr('data-skip');
            if (typeof skip == typeof undefined) {
                selectToggle(e, !beginning);
                appendElementToList();
            }
            beginning = false;
        });

        //FORM VALIDATION
        window.Parsley.addValidator('requiredIf', {
            validateString: function(value, requirement) {
                if ($(requirement).is(':checked')) return value !== '';
            },
            priority: 33,
        });
        $(FORM.formId).parsley(PARSLEY_CONFIG);
        // Activate popover and add 'hover' trigger
        $('[data-toggle="popover"]').popover({ html: true, trigger: 'hover' });

        // Manipulate Bootstrap select classes to enable just _select.scss styles

        // $('.selectpicker').selectpicker('refresh');

        $(document).on('change', '.selectpicker', function() {
            $('.selectpicker').selectpicker('refresh');
            // console.log('changeSelect');
        });

        // changing the class on the container:
        //$('.selectpicker').removeClass('btn-group bootstrap-select').selectpicker('setStyle');
        $('.selectpicker')
            .addClass('btn-group')
            .selectpicker('setStyle');

        // changing the class(es) on the button (altering data-style):
        //$('.selectpicker').selectpicker('setStyle', 'btn-default', 'remove'); //btn-light
        jQuery('select')
            .siblings('button')
            .removeClass('btn-light');
        jQuery('.selectpicker').selectpicker('setStyle', 'btn-default', 'add');
        // $('.selectpicker').selectpicker('refresh').trigger('change');

        $('.bootstrap-select > .dropdown-menu > ul > li').on(
            'click',
            function() {
                $(this)
                    .parents('.bootstrap-select')
                    .addClass('bootstrap-select-selected');
            }
        );

        // Apply style to already selected selectpicker
        $('select').each(function() {
            // console.log(this);
            const value = $(this).val();
            const element = $(this);
            if (value)
                element
                    .parents('.bootstrap-select')
                    .addClass('bootstrap-select-selected');
        });

        $('.selectpicker')
            .selectpicker('refresh')
            .trigger('change');

        //THIS SHOULD FORCE bootstrap-select to chose Bootstrap 4 mode... but...
        $.fn.selectpicker.Constructor.BootstrapVersion = '4';
    });
}
