// IE11, polyfill hack
// https://forum.vuejs.org/t/nothing-but-blank-pages-in-internet-explorer-11/5638/11
// Need to be before Vue instance to resolve all promises
// JS
// import * as $ from 'jquery';
/* eslint-disable */
window.$ = window.jQuery = require('jquery'); // required for bootstrap
import '../services/i18n/i18n';

require('bootstrap');
require('popper.js');
require('parsleyjs');
require('nouislider');
require('bootstrap-select');
window.qq = require('fine-uploader/fine-uploader/fine-uploader');

// STYLES
import 'fine-uploader/fine-uploader/fine-uploader-new.css';
import './../styles/default/main.scss';

// ONB Scripts
import '../scripts/onboarding/aml.js';
import '../scripts/onboarding/investimento.js';
import '../scripts/onboarding/informativa_contratto.js';
import '../scripts/onboarding/nostriPortafogli.js';
import '../scripts/onboarding/nostraProposta.js';

// REQUIREMENTS
//
// intl-tel-input **
// libphonenumber-js **
// parsleyjs **
// vue-raven **
// vue-select **
// vue2-autocomplete-js **
// vue2-autocomplete-js/dist/style/vue2-autocomplete.css
// vue2-dropzone **
// vue2-dropzone/dist/vue2Dropzone.css

// window.onload = function () {
//
//
//     // Add autocomplete capabilities to inputs
//     var j = $('#id_email');
//     if ($('html').has(j).length) {
//         j.attr("autocomplete", "nope");
//     }
//
// }

$('#id_new_password1').on('focusout', function() {
    var input = $(this).val();
    var regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$');
    // alert(input);
    if (regex.test(input)) {
        // alert('true');
        $(this)
            .addClass('valid')
            .removeClass('error');
        jQuery('.help-block').hide();
    } else {
        // alert('false');
        $(this)
            .removeClass('valid')
            .addClass('error');
        jQuery('.help-block').show();
    }
    // return;
});
