import {qs} from '../utils';
// const stickybits = require("stickybits");
import stickybits from 'stickybits';

//ingloba (copia...) le SVG di AmCharts (si potrebbe farlo a mano unaTantum risparmiando tempo sulla prima build)
// const req = require.context("./../../../node_modules/amcharts3/amcharts/images", true, /.*\.svg$/);
// req.keys().forEach(function (key) {
//     req(key);
// });
// const AmCharts_path = '/static/fe/img/'; //path per svg AmCharts
const AmCharts_path = 'https://cdn.amcharts.com/lib/3/images/'; //path per svg AmCharts

const $ = require('jquery');
// require('bootstrap');
// require('popper.js');
// window.popover = require('popper.js');

// Bootstrap dependencies
window.$ = window.jQuery = require('jquery'); // required for bootstrap
require('popper.js'); // required for tooltip, popup...
require('bootstrap');

window._ = require('lodash');
window.noUiSlider = require('nouislider');
const noUiSlider = require('nouislider');
require('./numberStatic.js');
require('amcharts3/amcharts/amcharts');
require('amcharts3/amcharts/serial');
require('amcharts3/amcharts/plugins/responsive/');
require('amcharts3/amcharts/themes/light/');
window.d3 = require('d3');
window.d3pie = require('d3pie'); // richiede specifiche versioni, max usabili:  "d3": "3.5.17" + "d3pie": "0.1.9"
require('libphonenumber-js');

if (qs('#onbInvId')) {
    // 0.1
    const poperConfig = {
        html: true,
        trigger: 'hover', // click, hover, focus
        selector: '[data-toggle="popover"]',
        container: 'body',
    };

    const options = {
        backdrop: 'true',
        keyboard: 'true',
    };

    // Signup form validation
    // Validation configuration
    const PARSLEY_CONFIG = {
        errorClass: 'has-error',
        successClass: 'has-success',
        classHandler: function (el) {
            return el.$element;
        },
        errorsWrapper: "<span class='help-block'></span>",
        errorTemplate: '<span></span>',
    };

    // BOTTO DI ROBA
    // 0.2
    // if (typeof dataLayer !== 'undefined') {
    //     dataLayer.push(obj_analytics);
    // }

    const lightenDarkenColor = function (col, amt) {
        // Positive amt lighten the color negative darken it.
        var usePound = false;
        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }
        var num = parseInt(col, 16);
        var r = (num >> 16) + amt;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;
        var b = ((num >> 8) & 0x00ff) + amt;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;
        var g = (num & 0x0000ff) + amt;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        return (
            (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
        ).toUpperCase();
    };

    // 0.3 VARIABILI ?

    let pie = null;
    let pieContent = null;
    let currentPortolio = {}; //più sotto l'oggetto viene sovrascritto da Ajax?
    let categories = {}; //più sotto l'oggetto viene sovrascritto da Ajax?

    const assetClassColorWeightMap = {
        Obbligazionario_Breve_Termine: ['#315D72', 1],
        Obbligazionario: ['#949BAA', 2],
        Credito: ['#D58629', 3],
        Azionario: ['#BB2E2F', 4],
        Materie_Prime: ['#C8B226', 5],
        Investimenti_Alternativi: ['#4C273F', 6],
        Bilanciato: ['#000000', 7],
        Monetario: ['#000000', 8],
        Altro: ['#000000', 9],
    };

    // 0.4 Fn ?
    // FROM COMPARATORE COSTI...
    const setupComparatoreCostiFn = function () {
        var thousandSep = function (num) {
            var text = Math.round(num).toString();
            return text.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
        };

        var addeuro = function () {
            $('#amountComparator')
                .find('.noUi-tooltip')
                .prepend('<i class="fa fa-eur" aria-hidden="true"></i>');
        };

        var costCalculator = {
            lowerFee: 0.007,
            lowFee: 0.006,
            mediumFee: 0.0055,
            higherFee: 0.004,
            longTermReturn: 0.038,
            competitorFeeFactor: 0.0165,

            fee: function (amount) {
                var fee = 0;
                switch (true) {
                    case amount >= 15000 && amount < 100000:
                        fee = this.lowFee;
                        break;
                    case amount >= 100000 && amount < 1000000:
                        fee = this.mediumFee;
                        break;
                    case amount >= 1000000:
                        fee = this.higherFee;
                        break;
                    default:
                        fee = this.lowerFee;
                        break;
                }

                return fee;
            },

            monthlyFee: function (amount) {
                return (amount * (this.fee(amount) / 12)).toFixed(0);
            },

            competitorsMonthlyFee: function (amount) {
                return ((amount * this.competitorFeeFactor) / 12).toFixed(0);
            },

            longtermSavings: function (amount) {
                var fee = this.fee(amount);
                var term1 = Math.pow(1 + this.longTermReturn - fee, 10);
                var term2 = Math.pow(1 + this.longTermReturn - 0.0165, 10);
                return amount * (term1 - term2);
            },
        };

        var range = document.getElementById('amountComparator');
        var startingAmount = parseInt(range.dataset.amount.replace(/\./g, ''));

        if (range.noUiSlider) range.noUiSlider.destroy();

        noUiSlider.create(range, {
            start: [startingAmount],
            step: 1000,
            tooltips: true,
            range: {
                min: [5000],
                '20%': [50000, 1000],
                '50%': [100000, 10000],
                '70%': [500000, 100000],
                '80%': [1000000, 100000],
                max: [2000000],
            },
            format: wNumb({
                decimals: 0,
                thousand: '.',
            }),
        });

        var updateCosts = function () {
            var amnt = parseInt(range.noUiSlider.get().replace(/\./g, ''));
            var fees = costCalculator.monthlyFee(amnt);
            const annualFees = (costCalculator.fee(amnt) * 100)
                .toFixed(2)
                .toString()
                .replace('.', ',');
            var savings = costCalculator.longtermSavings(amnt);
            var competitorFees = costCalculator.competitorsMonthlyFee(amnt);
            $('#amountComparator')[0].dataset.amount = amnt;
            $('#cost').text(fees);
            $('#annual-fees').text(annualFees);
            $('#competitor-cost').text(competitorFees);
            $('#savings').text(thousandSep(savings));
        };

        range.noUiSlider.on('update', updateCosts);
        range.noUiSlider.on('update', addeuro);
        updateCosts();

        //THIS FIX $('#amountComparator')[0].noUiSlider.on('update', function (e) {
        document.getElementById('amountComparator').noUiSlider.on('update', function (e) {
            var amountTarget = $('#amount');
            amountTarget.val(amountComparator.dataset.amount);
            var amountVal = amountTarget.val();
            refreshIntruments(
                currentPortolio,
                parseInt(amountVal.replace(/\./g, '')) / 100,
                '#assets',
                categories
            ); // TODO: Does this works?
            updateCostTable();
            redrawCharts();
        });
    };
    //end ComparatoreCosti

    const profileRiskValueToString = function (value) {
        const newValue = parseFloat(value);
        if (1 <= newValue && newValue < 2) return 'Molto basso';
        else if (2 <= newValue && newValue < 3) return 'Basso';
        else if (3 <= newValue && newValue < 4) return 'Medio basso';
        else if (4 <= newValue && newValue < 5) return 'Medio';
        else if (5 <= newValue && newValue < 6) return 'Medio alto';
        else if (6 <= newValue && newValue < 7) return 'Alto';
        else if (newValue == 7) return 'Molto alto';
        else return ' ';
    };

    const percBy = function (portfolio, prop) {
        var g = _.groupBy(portfolio, prop);
        return _.mapValues(g, function (v) {
            return _.sumBy(v, 'weight') * 100;
        });
    };

    const pieChartContent = function (portfolio) {
        var assetClasses = percByAssetClass(portfolio);
        var assetClassNameAndPerc = _.toPairs(assetClasses);
        var asContents = function (o) {
            var l = o[0].replace(/ /g, '_');
            return {
                label: o[0],
                value: o[1],
                color: assetClassColorWeightMap[l][0],
                sortingOrder: assetClassColorWeightMap[l][1],
            };
        };
        return _.sortBy(
            _.map(assetClassNameAndPerc, asContents),
            'sortingOrder'
        );
    };

    let percByAssetClass = _.partialRight(percBy, 'asset_class');
    let percBySubAssetClass = _.partialRight(percBy, 'sub_asset_class');

    const normalizeName = function (name) {
        return name
            .toString()
            .toLowerCase()
            .replace(/ /g, '_')
            .replace('.', '');
    };

    const assetItem = function (
        name,
        weight,
        amount,
        description,
        dataTargetPrefix,
        colorHex
    ) {
        var displayWeight = number(weight, 1, ',', '.');
        // console.log('displayWeight ', displayWeight);
        // Rounding weight before multiplying for the amount.

        var valueAmount = Math.round(weight) * amount;

        var displayAmount = number(valueAmount, 0, ',', '.');
        // console.log('displayAmount ', displayAmount);

        description = description !== null ? description.trim() : '';
        let iconDescription = !description
            ? `style="visibility:hidden;"`
            : `data-toggle="popover" data-placement="right" data-content="${description}"  `;

        var item = $('<div>')
            .css('color', colorHex)
            .css('border-color', colorHex)
            .append(dataTargetPrefix ? $() : '')
            .append($('<i class="fa fa-chevron-right"></i>'))
            .append(
                $('<span>')
                    .addClass('name')
                    .text(name + ' ')
            )
            .append(
                $(
                    '<span class="descrizione-popover" ' +
                    iconDescription +
                    ' "><i class="fa fa-info" aria-hidden="true"></i></span>'
                )
            )
            .append(
                $('<span>')
                    .addClass('perc')
                    .text(displayWeight)
            )
            .append(
                $('<span>')
                    .addClass('amount')
                    .text(displayAmount)
            )
            .append(
                $('<p>')
                    .addClass('block')
                    .text(description)
            );

        if (colorHex) {
            // item.css('border-left-color', colorHex);
        }
        if (dataTargetPrefix) {
            var target = '#' + dataTargetPrefix + normalizeName(name);
            item.click(function () {
                var clicks = $(this).data('clicks');
                if (clicks) {
                    $(item).removeClass('collapsed');
                    $(item)
                        .find('i.fa')
                        .removeClass('fa-chevron-down')
                        .addClass('fa-chevron-right');
                    $(target).slideUp(150);
                } else {
                    $(item).addClass('collapsed');
                    $(item)
                        .find('i.fa')
                        .removeClass('fa-chevron-right')
                        .addClass('fa-chevron-down');
                    $(target).slideDown(150);
                }
                $(this).data('clicks', !clicks);
            });
        }

        return $('<li>')
            .css('color', colorHex)
            .attr('id', normalizeName(name))
            .append(item);
    };

    const assetList = function (name, idPrefix) {
        return $('<ul>')
            .attr('id', idPrefix + normalizeName(name))
            .hide();
    };

    const assetClassesSortedByRank = function (keyClasses) {
        var keyClassNamesSortedDesc = Object.keys(keyClasses).sort(function (
            a,
            b
        ) {
            var a_val = assetClassColorWeightMap[a.replace(/ /g, '_')][1];
            var b_val = assetClassColorWeightMap[b.replace(/ /g, '_')][1];
            return a_val - b_val;
        });
        var keyClassesSortedDesc = [];
        for (var a in keyClassNamesSortedDesc) {
            var keyClassName = keyClassNamesSortedDesc[a];
            keyClassesSortedDesc.push([keyClassName, keyClasses[keyClassName]]);
        }
        return keyClassesSortedDesc;
    };

    const createRelatedAssets = function (
        sortedRelatedAssets,
        subAssetClasses,
        colorHex,
        amount,
        domSubAssets
    ) {
        let initialTintLevel = 20;
        _.forEach(sortedRelatedAssets, function (value) {
            var subAssetName = value[0];
            var funds = value[1];
            var subAssetWeight = subAssetClasses[subAssetName];
            var subColorHex = lightenDarkenColor(colorHex, initialTintLevel);
            var prefix = 'instruments';
            var subassetObj = _.find(categories, {label: subAssetName});
            var subassetDescr = '';
            if (subassetObj) {
                subassetDescr = subassetObj.description;
            } // else {  console.log(subAssetName, 'NOT found'); }

            // APPENDE RIGHE ASSETS
            var domSubAsset = assetItem(
                subAssetName,
                subAssetWeight,
                amount,
                subassetDescr,
                prefix,
                subColorHex
            ).appendTo(domSubAssets);

            // APPENDE RIGHE subASSETS
            var domInstruments = assetList(subAssetName, prefix).appendTo(
                domSubAsset
            );
            initialTintLevel += 20;
            var sortedFunds = _.sortBy(funds, 'weight').reverse();

            //Creating only first fund with detailed information.
            var firstFund = sortedFunds[0],
                remainingFunds = sortedFunds.splice(1);
            assetItem(
                firstFund.name,
                firstFund.weight * 100,
                amount,
                firstFund.fund_descr
            ).appendTo(domInstruments);

            //Creating an aggregate of the remaining funds.
            var sumWeightRemainingFunds = _.sumBy(remainingFunds, function (x) {
                return x.weight;
            });
            if (sumWeightRemainingFunds > 0) {
                assetItem(
                    'Altri fondi ed ETF',
                    sumWeightRemainingFunds * 100,
                    amount,
                    'Visibili solo ai clienti'
                ).appendTo(domInstruments);
            }
        });
    };

    const refreshIntruments = function (
        portfolio,
        amount,
        domAssets,
        assetSubassetDescr
    ) {
        var assetClasses = percByAssetClass(portfolio);
        var subAssetClasses = percBySubAssetClass(portfolio);
        var assetClassesSorted = assetClassesSortedByRank(assetClasses);

        $(domAssets).empty();

        _.forEach(assetClassesSorted, function (value) {
            var assetName = value[0];
            var assetWeight = value[1];
            var colorHex =
                assetClassColorWeightMap[assetName.replace(/ /g, '_')][0];
            var prefix = 'subassets';
            var assetDescr = _.find(assetSubassetDescr, {label: assetName})
                .description;
            var domAsset = assetItem(
                assetName,
                assetWeight,
                amount,
                assetDescr,
                prefix,
                colorHex
            ).appendTo(domAssets);
            var domSubAssets = assetList(assetName, prefix).appendTo(domAsset);

            var relatedSubAssetClass = _.filter(portfolio, {
                asset_class: assetName,
            });
            var relatedAssets = _.groupBy(
                relatedSubAssetClass,
                'sub_asset_class'
            );
            var newAssets = [];
            for (var prop in relatedAssets) {
                var elems = relatedAssets[prop];
                var sumElems = _.sumBy(elems, 'weight');
                newAssets.push([prop, relatedAssets[prop], sumElems]);
            }
            var indexTotalWeight = 2;
            var sortedRelatedAssets = _.sortBy(newAssets, function (x) {
                return x[indexTotalWeight];
            }).reverse();
            createRelatedAssets(
                sortedRelatedAssets,
                subAssetClasses,
                colorHex,
                amount,
                domSubAssets
            );
        });
        // console.log('refreshInstrument');
        // $('[data-toggle="popover"]').popover({'html': true, trigger: 'hover'});
    };

    const drawPerformanceChart = function (lineChartData) {
        var data = lineChartData['data'];
        var dates = lineChartData['dates'];

        // TODO Temporary
        var chartData = [];
        for (var i = 0; i < data.length; i++)
            chartData.push({date: dates[i], value: data[i]});

        var chart = AmCharts.makeChart('echart_line', {
            accessible: true,
            autoMarginOffset: 0,
            autoResize: true,
            borderAlpha: 0,
            type: 'serial',
            language: 'it',
            marginRight: 20,
            marginBottom: 10,
            marginTop: 10,
            marginLeft: 0,
            hideCredits: true,
            theme: 'light',
            fontFamily: 'Ubuntu',
            fontSize: 12,
            color: '#616161',
            mouseWheelZoomEnabled: true,
            dataDateFormat: 'DD-MM-YYYY',
            graphs: [
                {
                    id: 'g1',
                    bullet: 'round',
                    bulletBorderAlpha: 1,
                    bulletColor: '#FFFFFF',
                    bulletSize: 5,
                    lineColor: '#22A189',
                    hideBulletsCount: 50,
                    lineThickness: 2,
                    useLineColorForBulletBorder: true,
                    valueField: 'value',
                    balloon: {
                        adjustBorderColor: true,
                        borderThickness: 1.5,
                        borderColor: '#4eb3a0',
                        color: '#ffffff',
                        cornerRadius: 1,
                        fillColor: '#22A189',
                        fillAlpha: 1,
                        horizontalPadding: 10,
                        shadowColor: '#ffffff',
                        verticalPadding: 5,
                    },
                    balloonText:
                        "<span style='font-size:20px;'>[[value]]</span><br><span style='font-size:11px;'>[[date]]</span>",
                },
            ],
            chartScrollbar: {
                graph: 'g1',
                oppositeAxis: false,
                offset: 0,
                scrollbarHeight: 80,
                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: '#888888',
                graphFillAlpha: 0,
                graphLineAlpha: 0.5,
                selectedGraphFillAlpha: 0,
                selectedGraphLineAlpha: 1,
                autoGridCount: true,
                color: '#AAAAAA',
            },
            chartCursor: {
                animationDuration: 0.1,
                categoryBalloonDateFormat: 'DD MMM YYYY',
                pan: true,
                categoryLineEnabled: true,
                categoryLineBalloonEnabled: true,
                cursorPosition: 'middle',
                valueLineEnabled: false,
                valueLineBalloonEnabled: true,
                color: '#616161',
                cursorAlpha: 1,
                cursorColor: '#e1e1e1',
                leaveCursor: true,
                limitToGraph: 'g1',
                valueZoomable: true,
            },
            categoryField: 'date',
            categoryAxis: {
                axisAlpha: 1,
                axisColor: '#e1e1e1',
                axisThickness: 1,
                boldLabels: false,
                dashLength: 1,
                equalSpacing: false,
                gridAlpha: 0,
                gridThickness: 0,
                ignoreAxisWidth: false,
                inside: false,
                minorGridEnabled: true,
                parseDates: true,
                position: 'bottom',
            },
            marginsUpdated: true,
            valueAxes: [
                {
                    id: 'v1',
                    axisAlpha: 1,
                    position: 'left',
                    axisColor: '#e1e1e1',
                    axisThickness: 1,
                    boldLabels: false,
                    dashLength: 1,
                    equalSpacing: false,
                    gridAlpha: 0,
                    gridThickness: 0,
                    ignoreAxisWidth: false,
                    inside: false,
                    minorGridEnabled: true,
                    parseDates: true,
                },
            ],
            numberFormatter: {
                precision: -2,
                decimalSeparator: ',',
                thousandsSeparator: '.',
            },
            responsive: {
                enabled: true,
            },
            svgIcons: true,
            pathToImages: AmCharts_path,
        });
        chart.dataProvider = chartData;
        chart.validateData();

        function zoomChart() {
            //chart.zoomToIndexes(chart.dataProvider.length - 40, chart.dataProvider.length - 1);
            chart.zoomToIndexes(0, chart.dataProvider.length - 1);
        }

        chart.addListener('rendered', zoomChart);
        zoomChart();
    };

    const drawPerformancePerYearChart = function (barChartData) {
        // TODO Temporary
        var data = [];
        for (var i = 0; i < barChartData.length; i++)
            data.push({
                category: barChartData[i]['label'],
                'column-1': barChartData[i]['value'],
            });

        AmCharts.makeChart('perf_chart', {
            accessible: true,
            autoMarginOffset: 0,
            autoMargins: false,
            autoResize: true,
            borderAlpha: 0,
            categoryField: 'category',
            color: '#616161',
            columnWidth: 10,
            dataDateFormat: 'DD-MM-YYYY',
            fontFamily: 'Ubuntu',
            fontSize: 12,
            valueField: 'total',
            labelText: "<span style='font-size:20px;'>[[value]]</span>",
            hideCredits: true,
            language: 'it',
            marginRight: 20,
            marginBottom: 40,
            marginTop: 20,
            marginLeft: 20,
            startDuration: 1,
            theme: 'light',
            type: 'serial',
            categoryAxis: {
                axisAlpha: 1,
                axisColor: '#e1e1e1',
                axisThickness: 1,
                boldLabels: false,
                dashLength: 1,
                gridAlpha: 0,
                gridThickness: 0,
                offset: 0,
                position: 'bottom',
            },
            valueAxes: [
                {
                    axisAlpha: 0,
                    fixedColumnWidth: 150,
                    gridAlpha: 0,
                    id: 'ValueAxis-1',
                    labelsEnabled: false,
                    offset: 0,
                },
            ],
            graphs: [
                {
                    fillAlphas: 1,
                    id: 'AmGraph-2',
                    showBalloon: false,
                    type: 'column',
                    color: '#616161',
                    fontFamily: 'Ubuntu',
                    fontSize: 18,
                    labelText: '[[column-1]]',
                    valueField: 'column-1',
                    lineColor: '#22A189',
                    hideBulletsCount: 50,
                    fixedColumnWidth: 50,
                    showAllValueLabels: true,
                },
            ],
            dataProvider: data,
            svgIcons: true,
            pathToImages: AmCharts_path,
        });
    };

    const setupSlider = function (start, costraint, callback) {
        var riskSlider = document.getElementById('risk-range');
        noUiSlider.create(riskSlider, {
            step: DATA_INVEST.slider_step,
            start: parseInt(DATA_INVEST.portfolio_risk_safe),
            range: {
                min: 1,
                max: 7,
            },
            connect: false,
            tooltips: true,
            format: wNumb({decimals: 2}),
            pips: {
                mode: 'values',
                values: [1, 2, 3, 4, 5, 6, 7],
                density: 4,
            },
        });

        riskSlider.noUiSlider.on('change', function (values, handle) {
            var highRiskMsg = $('#high-risk-message');
            var risk = values[handle];
            if (risk > costraint) {
                risk = costraint;
                highRiskMsg.fadeIn();
                setTimeout(function () {
                    highRiskMsg.fadeOut(500);
                }, 3000);
                riskSlider.noUiSlider.set(costraint);
            }

            // try {
            //     dataLayer.push({
            //         event: 'slider',
            //         eventCategory: 'Bottoni',
            //         eventAction: 'Slider Personalizza',
            //     });
            // } catch (err) {
            //     window.console &&
            //         console.log('No datalayer? ' + err.toString());
            // }

            $('#portfolio-risk').val(parseInt(risk));
            $('#selected-risk').html(parseInt(risk));
            callback();
        });

        return riskSlider;
    }; // depends on noUiSlider

    const setupPie = function (currentPortfolio) {
        pieContent = pieChartContent(currentPortfolio);

        var formatLabel = function (label) {
            var newLabel = label;
            return newLabel.toUpperCase();
        };

        var innerText = function (pie, obj, color) {
            $('#pie-title')
                .text(formatLabel(obj.data.label))
                .css('color', color);
            $('#pie-perc')
                .text(Math.round(obj.data.value) + '%')
                .css('color', color);
        };

        var closeBtn = $('#backtoAssetClass');

        closeBtn.on('click', function (event) {
            $(this).hide();
            event.preventDefault();
            resetPie();
            $('.collapsed').click();
        });

        return new d3pie('#pieChart', {
            size: {
                canvasWidth: 340,
                canvasHeight: 340,
                pieInnerRadius: '63%',
                pieOuterRadius: '100%',
            },
            labels: {
                inner: {
                    format: 'none',
                    hideWhenLessThanPercentage: 1,
                },
                outer: {
                    format: 'none',
                    pieDistance: 32,
                },
                mainLabel: {
                    fontSize: 18,
                    font: 'Ubuntu',
                },
                percentage: {
                    fontSize: 14,
                    color: '#ffffff',
                    decimalPlaces: 0,
                },
                value: {
                    color: '#adadad',
                    fontSize: 18,
                },
                lines: {
                    enabled: true,
                },
                truncation: {
                    enabled: false,
                },
            },
            data: {
                content: pieContent,
            },
            effects: {
                load: {
                    effect: 'none',
                    speed: 500,
                },
                pullOutSegmentOnClick: {
                    effect: 'linear',
                    speed: 500,
                    size: 6,
                },
            },
            callbacks: {
                onClickSegment: function (obj) {
                    var openSegmentIndex = obj.index;
                    var openSegmentColor = pieContent[obj.index].color;
                    var assetClassName = obj.data.label;
                    if (!obj.data.hasOwnProperty('subasset')) {
                        var assetClassObjects = _.filter(
                            currentPortfolio,
                            function (x) {
                                return x.asset_class === assetClassName;
                            }
                        );
                        var subAssets = _.groupBy(
                            assetClassObjects,
                            'sub_asset_class'
                        );

                        var subAssetsWithWeight = [];
                        for (var prop in subAssets) {
                            var elems = subAssets[prop];
                            var sumElems = _.sumBy(elems, 'weight');
                            subAssetsWithWeight.push([
                                prop,
                                subAssets[prop],
                                sumElems,
                            ]);
                        }
                        var sortedSubAssetsWithWeight = _.sortBy(
                            subAssetsWithWeight,
                            function (x) {
                                return x[2];
                            }
                        ).reverse();
                        var newSegmentContent = [];
                        var initialTintLevel = 20;
                        for (var prop in sortedSubAssetsWithWeight) {
                            newSegmentContent.push({
                                label: sortedSubAssetsWithWeight[prop][0],
                                value: sortedSubAssetsWithWeight[prop][2] * 100,
                                color: lightenDarkenColor(
                                    openSegmentColor,
                                    initialTintLevel
                                ),
                                subasset: true,
                                asset: obj.data.label,
                                assetPerc: obj.data.value,
                            });
                            initialTintLevel += 20;
                        }

                        var before = _.take(pieContent, openSegmentIndex);
                        var after = _.slice(pieContent, openSegmentIndex + 1);
                        var newPieContent = _.concat(
                            before,
                            newSegmentContent,
                            after
                        );

                        $('#pieChart').fadeTo(250, 0.5, function () {
                            pie.updateProp('data.content', newPieContent);
                            $('#pieChart').fadeTo(300, 1);
                        });
                        var l = obj.data.label.replace(/ /g, '_');
                        var color = assetClassColorWeightMap[l][0];
                        innerText(pie, obj, color);
                        pieContent = newPieContent;
                    } else {
                        innerText(pie, obj, obj.data.color);
                    }

                    closeBtn.show();

                    var elemName =
                        '#' +
                        normalizeName(obj.data.label.toLowerCase()) +
                        '>div';

                    if (obj.data.hasOwnProperty('subasset')) {
                        var assetName =
                            '#' + normalizeName(obj.data.asset) + '>div';
                        if (!$(assetName).hasClass('collapsed'))
                            $(assetName).click();
                        $(elemName).click();
                    } else if (!$(elemName).hasClass('collapsed'))
                        $(elemName).click();
                },
                onMouseoverSegment: function (obj) {
                    innerText(pie, obj, obj.data.color);
                },
            },
        });
    };

    const setupRischioBox = function () {
        var $rischio_box = $('.onboarding-investimento-rischio-container');
        var $rischio_info = $('.onboarding-investimento-rischio-indicazione');
        var $rischio_box_button = $('.open');

        $('.close-container').on('click', function () {
            $rischio_box.css('display', 'none');
            $rischio_info.css('display', 'inline');
            $rischio_box_button.css('display', 'inline');
        });

        $rischio_box_button.on('click', function () {
            $rischio_box_button.css('display', 'none');
            $rischio_info.css('display', 'none');
            $rischio_box.css('display', 'inline');
        });
    };

    const setupAmountBox = function () {
        var amountTarget = $('#amount');
        // Add format to investment amount input
        amountTarget.number(true, '.', ' ', '.');
        // console.log('amountTarget ', amountTarget);

        amountTarget.on('keyup', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
            var amountVal = amountTarget.val();
            var amountComparator = document.getElementById('amountComparator');
            amountComparator.dataset.amount = amountVal;
            setTimeout(
                // prevents toooo many ajax call on typing
                () => {
                    setupComparatoreCostiFn(); // FIXME: WTF !!!
                    refreshIntruments(
                        currentPortolio,
                        parseInt(amountVal.replace(/\./g, '')) / 100,
                        '#assets',
                        categories
                    ); // TODO: Does this works?
                    updateCostTable();
                    redrawCharts();
                },
                1200
            );
        });

        // $('#amountComparator')[0].noUiSlider.on('update', function (e) {
        //     var amountTarget = $("#amount");
        //     amountTarget.val(amountComparator.dataset.amount);
        //     var amountVal = amountTarget.val();
        //     refreshIntruments(currentPortolio, parseInt(amountVal.replace(/\./g, '')) / 100, '#assets', categories); // TODO: Does this works?
        //     updateCostTable();
        //     redrawCharts();
        // });
    };

    const portfolioRiskLabel = function (risk_level) {
        var label = '';
        if (risk_level <= 3) label = 'Basso';
        if (risk_level > 3 && risk_level <= 5) label = 'Moderato';
        if (risk_level > 5 && risk_level <= 7) label = 'Medio';
        if (risk_level > 7) label = 'Alto';

        return label;
    };

    const resetPie = function () {
        if (pie) pie.destroy();
        // {# $('#backtoAssetClass').remove();#}
        pie = setupPie(currentPortolio);
        $('#pie-title')
            .text("Seleziona un'area")
            .css('color', '#a1a1a1');
        // {# $('#pie-title').text('');#}
        $('#pie-perc').text('');
    };

    const redrawPortfolio = function () {
        var amount = $('#amount').val();

        try {
            amount = parseInt(amount) / 100;
        } catch (err) {
            amount = 10000 / 100;
        }

        resetPie();
        refreshIntruments(currentPortolio, amount, '#assets', categories);
    };

    const fmt = function (x) {
        // x is not always a number
        var x_value = parseFloat(x);
        if (x_value === -0.0) return '0%';
        if (x_value < 0) return x.toString().replace('.', ',') + '%';
        return '+' + x.toString().replace('.', ',') + '%';
    };

    const perc_fmt = function (x) {
        x = x.toString().replace(',', '.');
        var x_value = parseFloat(x);
        if (x_value === -0.0) return '0%';
        return (
            Number(x)
                .toFixed(2)
                .toString()
                .replace('.', ',') + '%'
        );
    };

    const eur_fmt = function (x) {
        // console.log('€ X ', number(x, 0, ',', '.'));
        return '€ ' + number(x, 0, ',', '.');
    };

    const calc_total_fee = function () {
        var amount = parseFloat($('#amount').val());
        var fee = '0,60';
        var commissione_wm = DATA_INVEST.commissione_wm;

        if (commissione_wm !== '-1') {
            //string
            fee = commissione_wm;
            if (
                $('#commissioni_perc').val() != 'undefined' ||
                $('#commissioni_perc').val() != null
            ) {
                fee = $('#commissioni_perc').val();
            }
        }
        var perc = parseFloat(fee.replace(',', '.'));
        return Math.round((perc * amount) / 100);
    };

    const calc_total_cost = function () {
        var perc = parseFloat(
            $('#costi_perc')
                .text()
                .replace(',', '.')
        );
        var amount = parseFloat($('#amount').val());
        return Math.round((perc * amount) / 100);
    };

    const calc_total_sum_perc = function calc_total_sum_perc() {
        var costi = parseFloat(
            $('#costi_perc')
                .text()
                .replace(',', '.')
        );
        var commissioni = parseFloat(
            $('#commissioni_perc')
                .text()
                .replace(',', '.')
        );
        return Number(costi + commissioni).toFixed(2);
    };

    const calc_total_sum = function calc_total_sum() {
        var commissioni = parseFloat(
            $('#commissioni_tot')
                .text()
                .replace('€', '')
                .replace('.', '')
        );
        var costi = parseFloat(
            $('#costi_tot')
                .text()
                .replace('€', '')
                .replace('.', '')
        );
        return costi + commissioni;
    };

    // if wealth_management . qualcosa django - spostato in doc.READY

    const updateCostTable = function updateCostTable(wm_commissione_input) {
        $('#costi_tot').text(eur_fmt(calc_total_cost()));
        var amount = parseFloat($('#amount').val());
        var fee = '0,60';
        var commissione_wm = DATA_INVEST.commissione_wm;
        if (commissione_wm !== '-1') {
            fee = commissione_wm;
        }

        if (
            wm_commissione_input != 'undefined' &&
            wm_commissione_input != null
        ) {
            $('#commissioni_perc').text(perc_fmt(wm_commissione_input));
            $('#commissioni_perc').val(wm_commissione_input.replace(',', '.'));
            $('#_wm_commissione').val($('#commissioni_perc').val());
        } else if (commissione_wm != '-1') {
            $('#commissioni_perc').val(fee.replace(',', '.'));
            $('#commissioni_perc').text(perc_fmt(fee));
            $('#_wm_commissione').val($('#commissioni_perc').val());
        } else {
            $('#commissioni_perc').text(perc_fmt(fee));
        }
        $('#commissioni_tot').text(eur_fmt(calc_total_fee()));
        $('#tot_perc').text(perc_fmt(calc_total_sum_perc()));
        $('#tot').text(eur_fmt(calc_total_sum()));
    };

    $('#salvadanaio-hidden-wrapper').hide();
    let backValue = $('#portfolio-risk').val();

    if (DATA_INVEST.is_salvadanaio) {
        $('#rendimenti-storici').hide();
    }

    $('#trigger-core-salvadanaio').click(function () {
        backValue = $('#portfolio-risk').val();
        $('#salvadanaio-hidden-wrapper').show();
        $('#risk-range-wrapper').hide();
        $('.achievements-wrapper').hide();
        $('#rendimenti-storici').hide();
        $('#portfolio-risk').val(0);
        let riskSlider = document.getElementById('risk-range');
        riskSlider.noUiSlider.destroy();
        refreshPortfolio();
    });
    $('#salvadanaio-return-to-other-lines').click(function () {
        $('#salvadanaio-hidden-wrapper').hide();
        $('#risk-range-wrapper').show();
        $('.achievements-wrapper').show();
        $('#rendimenti-storici').show();
        $('#portfolio-risk').val(backValue);
        setupSlider(backValue, DATA_INVEST.max_risk_safe, refreshPortfolio);
        // setupSlider();
        refreshPortfolio();
    });

    const refreshPortfolio = function () {
        var risk = $('#portfolio-risk').val();
        if (DATA_INVEST.is_salvadanaio) {
            risk = "0";
        }
        var amount = $('#amount').val(); // TODO consoleDebug

        // console.log(`r: ${risk}, a: ${amount}, ms: ${DATA_INVEST.reccomended_risk}`);

        $.ajax({
            url: DATA_INVEST.onboarding_refresh_portfolio,
            method: 'POST',
            data: {r: risk, a: amount, ms: DATA_INVEST.reccomended_risk},
        }).done(function (pfolio) {
            $('#portfolioDescrFstSect').text(pfolio.description.rationale);
            $('#portfolioDescrSndSectTitle').text(
                pfolio.description.features_title
            );
            $('#portfolioDescrSndSectBody').text(pfolio.description.features);
            $('#portfolioDescrTrdSect').text(pfolio.description.content);
            $('#annualReturn').text(fmt(pfolio.performance.annual_return));
            $('#performanceYearToDate').text(
                pfolio.performance.per_year[
                pfolio.performance.per_year.length - 1
                    ].label
            );
            $('#performanceYearToDateVal').text(
                pfolio.performance.per_year[
                pfolio.performance.per_year.length - 1
                    ].value + '%'
            );
            $('#performanceWorstYear').text(pfolio.performance.worst.year);
            $('#performanceWorstYearVal').text(
                fmt(pfolio.performance.worst.val)
            );
            $('#costi_perc').text(perc_fmt(pfolio.oneri));

            function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) {
                    month = '0' + month;
                }
                if (day.length < 2) {
                    day = '0' + day;
                }
                return [day, month, year].join('-');
            }
            $('#data_oneri').text(formatDate(pfolio.data_oneri));

            updateCostTable();

            currentPortolio = pfolio.assets;
            categories = pfolio.categories;
            drawPerformanceChart(pfolio.performance.chart);
            drawPerformancePerYearChart(pfolio.performance.per_year);
            redrawPortfolio();
        });
    };

    const redrawCharts = function () {
        var risk = $('#portfolio-risk').val();
        var amount = $('#amount').val();
        $.ajax({
            url: DATA_INVEST.onboarding_refresh_charts,
            method: 'POST',
            data: {a: amount, r: risk},
        }).done(function (chartData) {
            $('#annualReturn').text(fmt(chartData.annual_return));
            $('#performanceBestYear').text(chartData.best.year);
            $('#performanceBestYearVal').text(fmt(chartData.best.val));
            $('#performanceWorstYear').text(chartData.worst.year);
            $('#performanceWorstYearVal').text(fmt(chartData.worst.val));
            $('#performanceYearToDateVal').text(
                chartData.per_year[chartData.per_year.length - 1].value + '%'
            );
            drawPerformanceChart(chartData.chart);
            drawPerformancePerYearChart(chartData.per_year);
        });
    };

    // READY?

    $(document).ready(function () {
        // Apply validation to sign up form
        $('#signup-form').parsley(PARSLEY_CONFIG);

        $('#password').on('click focus', function (event) {
            event.preventDefault();
            $('.password-notice').css('display', 'block');
        });

        // Custom validator for phone number
        window.Parsley.addValidator('mobile_phone', {
            validateString: function (value) {
                var hasInternationalPrefix = /^(\+[0-9]{2}).*$/.test(value);
                var parsedNum = hasInternationalPrefix
                    ? libphonenumber.parse(value)
                    : libphonenumber.parse(value, 'IT');
                return parsedNum.hasOwnProperty('phone');
            },
            messages: {
                it: 'Il numero di cellulare non risulta valido.',
            },
        });

        // Validation function
        var registrazioneForm = $('#signup-form');
        var registrazioneFormSubmitButton = $('#subBtn');
        registrazioneForm.submit(function () {
            registrazioneForm.parsley();
            $('#signup-form > button')
                .prop('disabled', true)
                .button('loading');
            return true;
        });

        //p2
        var $profileRisk = $('.onboarding-exp-investimento__profile__risk');
        $profileRisk.text(
            profileRiskValueToString($profileRisk.attr('data-risk'))
        );

        // Show sign-up and login form on mobile
        $('#show-signup-form').on('click', function () {
            $('.onboarding-exp-investimento__registration').addClass(
                'onboarding-exp-investimento__registration--force-visible'
            );
        });

        // Hide sign-up and login form on mobile
        $('#hide-signup-form').on('click', function () {
            $('.onboarding-exp-investimento__registration').removeClass(
                'onboarding-exp-investimento__registration--force-visible'
            );
        });

        if (DATA_INVEST.is_wmConfPage) {
            $('#commissioni_perc').on('change', function () {
                var valid_commissions = DATA_INVEST.valid_commissions;

                if (
                    valid_commissions.indexOf(
                        parseFloat($('#commissioni_perc').val())
                    ) != -1
                ) {
                    updateCostTable($('#commissioni_perc').val());
                } else {
                    $('#commissioneModal').modal('show');
                    var commissione_wm = DATA_INVEST.commissione_wm;
                    $('#commissioni_perc').val(
                        commissione_wm.replace(',', '.')
                    );
                }
                $('#_wm_commissione').val($('#commissioni_perc').val());
            });
        }
        // p3

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRFToken', DATA_INVEST.csrf_token);
            },
        });

        setupSlider(20, DATA_INVEST.max_risk_safe, refreshPortfolio);

        if (DATA_INVEST.portafoglio_view_only) {
            //{% if portafoglio_view_only %}
            document
                .getElementById('risk-range')
                .setAttribute('disabled', true);
        } // {% endif %}

        setupRischioBox();
        setupAmountBox();
        refreshPortfolio();

        // p3.b

        $('#confirm_investment_check').on('change', function () {
            if (this.checked) {
                $('#confirm_investment').prop('disabled', false);
            } else {
                $('#confirm_investment').prop('disabled', true);
            }
        });

        window.dataLayer = window.dataLayer || [];

        $('#confirm_investment').on('click', function () {
            var current_risk = $('#portfolio-risk').val();
            var default_risk = DATA_INVEST.portfolio_risk_safe;
            let utm_data = DATA_SIGNUP.utm_params;
            // tag-manager 5
            if (parseFloat(current_risk) != parseFloat(default_risk)) {
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'investment_confirmed',
                        eventLabel: 'Cambiato',
                        uid: utm_data.uid ? utm_data.uid : null,
						campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
						source: utm_data.utm_source ? utm_data.utm_source : null,
						medium: utm_data.utm_medium ? utm_data.utm_medium : null
                    });
                }
            } else {
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'investment_confirmed',
                        eventLabel: 'Confermato',
                        uid: utm_data.uid ? utm_data.uid : null,
						campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
						source: utm_data.utm_source ? utm_data.utm_source : null,
						medium: utm_data.utm_medium ? utm_data.utm_medium : null
                    });
                }
            }
        });

        $('ul.tabs-exp li').click(function () {
            var tabId = $(this).attr('data-tab-exp');
            var currentTabPane = $(this).closest('.tab-pane');

            currentTabPane.find('ul.tabs-exp li').removeClass('current');
            currentTabPane.find('.tab-exp-content').removeClass('current');

            $(this).addClass('current');
            $('#' + tabId).addClass('current');
            resetPie();
        });

        $('#promo').on('click', function (event) {
            event.preventDefault();
            $('#promoModal').modal('show');
        });

        $('#openbuildPortfolio').click(function () {
            $('#modaleBuildPortfolio').modal('show');
        });

        $('#closebuildPortfolio').on('click', function () {
            $('#buildPortfolio').removeClass('.visible');
        });

        // in refreshIntruments()
        $('[data-toggle="popover"]')
            .off('hover')
            .popover(poperConfig);
        setupComparatoreCostiFn();

        $(".select_achievement").on("change", function (event) {
            var name = $(this).attr("data-name");
            $("#achievement_" + name).val($(this).val());
            refreshPortfolio();
            redrawCharts();
        });

        $(".select_achievement").each(function (item) {
            var name = $(this).attr("data-name");
            $("#achievement_" + name).val($(this).val());
        })

        $(document).popover(poperConfig);
    }); //end ready

    stickybits('.sticky-content', {
        // add and remove sticky CSS Classes as: .js-is-sticky and .js-is-stuck
        useStickyClasses: true,
    });
} // END CHECK onbInvId
