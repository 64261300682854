/* eslint-disable no-unused-vars */
/* eslint-disable no-compare-neg-zero */
import { qs } from '../utils';
// const stickybits = require("stickybits");
import stickybits from 'stickybits';

const axios = require('axios');

//ingloba (copia...) le SVG di AmCharts (si potrebbe farlo a mano unaTantum risparmiando tempo sulla prima build)
// const req = require.context("./../../../node_modules/amcharts3/amcharts/images", true, /.*\.svg$/);
// req.keys().forEach(function (key) {
//     req(key);
// });
// const AmCharts_path = '/static/fe/img/'; //path per svg AmCharts
const AmCharts_path = 'https://cdn.amcharts.com/lib/3/images/'; //path per svg AmCharts

const $ = require('jquery');
// require('bootstrap');
// require('popper.js');
// window.popover = require('popper.js');

// Bootstrap dependencies
window.$ = window.jQuery = require('jquery'); // required for bootstrap
require('popper.js'); // required for tooltip, popup...F
require('bootstrap');

window._ = require('lodash');
window.noUiSlider = require('nouislider');
const noUiSlider = require('nouislider');
require('./numberStatic.js');
require('amcharts3/amcharts/amcharts');
require('amcharts3/amcharts/serial');
require('amcharts3/amcharts/plugins/responsive/');
require('amcharts3/amcharts/themes/light/');
window.d3 = require('d3');
window.d3pie = require('d3pie'); // richiede specifiche versioni, max usabili:  "d3": "3.5.17" + "d3pie": "0.1.9"
require('libphonenumber-js');

if (qs('#onbProposta')) {
    // TODO : clean consoleDebug
    // console.log('TPL-onb-investimento');
    // console.log('obj_analytics: ', obj_analytics);
    // console.log('DATA_CHOSEN_CORE: ', DATA_CHOSEN_CORE);

    // 0.1
    const poperConfig = {
        html: true,
        trigger: 'hover', // click, hover, focus
        selector: '[data-toggle="popover"]',
        container: 'body',
    };

    const options = {
        backdrop: 'true',
        keyboard: 'true',
    };

    // FIRST PARSLEY_CONFIG: THIS WAS this file start - but Y????!???!?
    // // Signup form validation
    // // Validation configuration
    const PARSLEY_CONFIG = {
        errorClass: 'has-error',
        successClass: 'has-success',
        classHandler: function(el) {
            return el.$element;
        },
        errorsWrapper: "<span class='help-block'></span>",
        errorTemplate: '<span></span>',
    };

    // BOTTO DI ROBA

    // 0.2
    // if (typeof dataLayer !== 'undefined') {
    //     dataLayer.push(obj_analytics);
    // }

    const lightenDarkenColor = function(col, amt) {
        // Positive amt lighten the color negative darken it.
        let usePound = false;
        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }
        let num = parseInt(col, 16);
        let r = (num >> 16) + amt;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;
        let b = ((num >> 8) & 0x00ff) + amt;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;
        let g = (num & 0x0000ff) + amt;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        return (
            (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
        ).toUpperCase();
    };

    // 0.3 VARIABILI ?

    let pie = null;
    let pieContent = null;
    let currentPortolio = {}; //più sotto l'oggetto viene sovrascritto da Ajax?
    let categories = {}; //più sotto l'oggetto viene sovrascritto da Ajax?

    const assetClassColorWeightMap = {
        Obbligazionario_Breve_Termine: ['#315D72', 1],
        Obbligazionario: ['#949BAA', 2],
        Credito: ['#D58629', 3],
        Azionario: ['#BB2E2F', 4],
        Materie_Prime: ['#C8B226', 5],
        Investimenti_Alternativi: ['#4C273F', 6],
        Bilanciato: ['#000000', 7],
        Monetario: ['#000000', 8],
        Altro: ['#000000', 9],
    };

    // 0.4 Fn ?
    // FROM COMPARATORE COSTI...
    const setupComparatoreCostiFn = function() {
        let thousandSep = function(num) {
            let text = Math.round(num).toString();
            return text.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
        };

        let addeuro = function() {
            $('#amountComparator')
                .find('.noUi-tooltip')
                .prepend('<i class="fa fa-eur" aria-hidden="true"></i>');
        };

        let costCalculator = {
            lowerFee: 0.007,
            lowFee: 0.006,
            mediumFee: 0.0055,
            higherFee: 0.004,
            longTermReturn: 0.038,
            competitorFeeFactor: 0.0165,

            fee: function(amount) {
                let fee = 0;
                switch (true) {
                    case amount >= 15000 && amount < 100000:
                        fee = this.lowFee;
                        break;
                    case amount >= 100000 && amount < 1000000:
                        fee = this.mediumFee;
                        break;
                    case amount >= 1000000:
                        fee = this.higherFee;
                        break;
                    default:
                        fee = this.lowerFee;
                        break;
                }

                return fee;
            },

            monthlyFee: function(amount) {
                return (amount * (this.fee(amount) / 12)).toFixed(0);
            },

            competitorsMonthlyFee: function(amount) {
                return ((amount * this.competitorFeeFactor) / 12).toFixed(0);
            },

            longtermSavings: function(amount) {
                let fee = this.fee(amount);
                let term1 = Math.pow(1 + this.longTermReturn - fee, 10);
                let term2 = Math.pow(1 + this.longTermReturn - 0.0165, 10);
                return amount * (term1 - term2);
            },
        };

        let range = document.getElementById('amountComparator');
        let startingAmount = parseInt(range.dataset.amount.replace(/\./g, ''));

        if (range.noUiSlider) range.noUiSlider.destroy();

        noUiSlider.create(range, {
            start: [startingAmount],
            step: 1000,
            tooltips: true,
            range: {
                min: [5000],
                '20%': [50000, 1000],
                '50%': [100000, 10000],
                '70%': [500000, 100000],
                '80%': [1000000, 100000],
                max: [2000000],
            },
            format: wNumb({
                decimals: 0,
                thousand: '.',
            }),
        });

        const updateCosts = function() {
            let amnt = parseInt(range.noUiSlider.get().replace(/\./g, ''));
            let fees = costCalculator.monthlyFee(amnt);
            const annualFees = (costCalculator.fee(amnt) * 100)
                .toFixed(2)
                .toString()
                .replace('.', ',');
            let savings = costCalculator.longtermSavings(amnt);
            let competitorFees = costCalculator.competitorsMonthlyFee(amnt);
            $('#amountComparator')[0].dataset.amount = amnt;
            $('#cost').text(fees);
            $('#annual-fees').text(annualFees);
            $('#competitor-cost').text(competitorFees);
            $('#savings').text(thousandSep(savings));
        };

        range.noUiSlider.on('update', updateCosts);
        range.noUiSlider.on('update', addeuro);
        updateCosts();

        //THIS FIX $('#amountComparator')[0].noUiSlider.on('update', function (e) {
        document
            .getElementById('amountComparator')
            .noUiSlider.on('update', function() {
                let amountTarget = $('#amount');
                amountTarget.val(amountComparator.dataset.amount);
                let amountVal = amountTarget.val();
                refreshIntruments(
                    currentPortolio,
                    parseInt(amountVal.replace(/\./g, '')) / 100,
                    '#assets',
                    categories
                ); // TODO: Does this works?
                updateCostTable();
                redrawCharts();
            });
    };
    //end ComparatoreCosti

    const profileRiskValueToString = function(value) {
        const newValue = parseFloat(value);
        if (1 <= newValue && newValue < 2) return 'Molto basso';
        else if (2 <= newValue && newValue < 3) return 'Basso';
        else if (3 <= newValue && newValue < 4) return 'Medio basso';
        else if (4 <= newValue && newValue < 5) return 'Medio';
        else if (5 <= newValue && newValue < 6) return 'Medio alto';
        else if (6 <= newValue && newValue < 7) return 'Alto';
        else if (newValue == 7) return 'Molto alto';
        else return ' ';
    };

    const percBy = function(portfolio, prop) {
        let g = _.groupBy(portfolio, prop);
        return _.mapValues(g, function(v) {
            return _.sumBy(v, 'weight') * 100;
        });
    };

    const pieChartContent = function(portfolio) {
        let assetClasses = percByAssetClass(portfolio);
        let assetClassNameAndPerc = _.toPairs(assetClasses);
        let asContents = function(o) {
            let l = o[0].replace(/ /g, '_');
            return {
                label: o[0],
                value: o[1],
                color: assetClassColorWeightMap[l][0],
                sortingOrder: assetClassColorWeightMap[l][1],
            };
        };
        return _.sortBy(
            _.map(assetClassNameAndPerc, asContents),
            'sortingOrder'
        );
    };

    let percByAssetClass = _.partialRight(percBy, 'asset_class');
    let percBySubAssetClass = _.partialRight(percBy, 'sub_asset_class');

    const normalizeName = function(name) {
        return name
            .toString()
            .toLowerCase()
            .replace(/ /g, '_')
            .replace('.', '');
    };

    const assetItem = function(
        name,
        weight,
        amount,
        description,
        dataTargetPrefix,
        colorHex
    ) {
        let displayWeight = number(weight, 1, ',', '.');
        // console.log('displayWeight ', displayWeight);
        // Rounding weight before multiplying for the amount.

        let valueAmount = Math.round(weight) * amount;

        let displayAmount = number(valueAmount, 0, ',', '.');
        // console.log('displayAmount ', displayAmount);

        description = description !== null ? description.trim() : '';
        let iconDescription = !description
            ? `style="visibility:hidden;"`
            : `data-toggle="popover" data-placement="right" data-content="${description}"  `;

        let item = $('<div>')
            .css('color', colorHex)
            .css('border-color', colorHex)
            .append(dataTargetPrefix ? $() : '')
            .append($('<i class="fa fa-chevron-right"></i>'))
            .append(
                $('<span>')
                    .addClass('name')
                    .text(name + ' ')
            )
            .append(
                $(
                    '<span class="descrizione-popover" ' +
                        iconDescription +
                        ' "><i class="fa fa-info" aria-hidden="true"></i></span>'
                )
            )
            .append(
                $('<span>')
                    .addClass('perc')
                    .text(displayWeight)
            )
            .append(
                $('<span>')
                    .addClass('amount')
                    .text(displayAmount)
            )
            .append(
                $('<p>')
                    .addClass('block')
                    .text(description)
            );

        if (colorHex) {
            // item.css('border-left-color', colorHex);
        }
        if (dataTargetPrefix) {
            let target = '#' + dataTargetPrefix + normalizeName(name);
            item.click(function() {
                let clicks = $(this).data('clicks');
                if (clicks) {
                    $(item).removeClass('collapsed');
                    $(item)
                        .find('i.fa')
                        .removeClass('fa-chevron-down')
                        .addClass('fa-chevron-right');
                    $(target).slideUp(150);
                } else {
                    $(item).addClass('collapsed');
                    $(item)
                        .find('i.fa')
                        .removeClass('fa-chevron-right')
                        .addClass('fa-chevron-down');
                    $(target).slideDown(150);
                }
                $(this).data('clicks', !clicks);
            });
        }

        return $('<li>')
            .css('color', colorHex)
            .attr('id', normalizeName(name))
            .append(item);
    };

    const assetList = function(name, idPrefix) {
        return $('<ul>')
            .attr('id', idPrefix + normalizeName(name))
            .hide();
    };

    const assetClassesSortedByRank = function(keyClasses) {
        let keyClassNamesSortedDesc = Object.keys(keyClasses).sort(function(
            a,
            b
        ) {
            let a_val = assetClassColorWeightMap[a.replace(/ /g, '_')][1];
            let b_val = assetClassColorWeightMap[b.replace(/ /g, '_')][1];
            return a_val - b_val;
        });
        let keyClassesSortedDesc = [];
        for (let a in keyClassNamesSortedDesc) {
            let keyClassName = keyClassNamesSortedDesc[a];
            keyClassesSortedDesc.push([keyClassName, keyClasses[keyClassName]]);
        }
        return keyClassesSortedDesc;
    };

    const createRelatedAssets = function(
        sortedRelatedAssets,
        subAssetClasses,
        colorHex,
        amount,
        domSubAssets
    ) {
        let initialTintLevel = 20;
        _.forEach(sortedRelatedAssets, function(value) {
            let subAssetName = value[0];
            let funds = value[1];
            let subAssetWeight = subAssetClasses[subAssetName];
            let subColorHex = lightenDarkenColor(colorHex, initialTintLevel);
            let prefix = 'instruments';
            let subassetObj = _.find(categories, { label: subAssetName });
            let subassetDescr = '';
            if (subassetObj) {
                subassetDescr = subassetObj.description;
            } // else {  console.log(subAssetName, 'NOT found'); }

            // TODO : consoleDebug
            // console.log(subAssetName, ' >> ', subassetDescr);

            // APPENDE RIGHE ASSETS
            let domSubAsset = assetItem(
                subAssetName,
                subAssetWeight,
                amount,
                subassetDescr,
                prefix,
                subColorHex
            ).appendTo(domSubAssets);

            // APPENDE RIGHE subASSETS
            let domInstruments = assetList(subAssetName, prefix).appendTo(
                domSubAsset
            );
            initialTintLevel += 20;
            let sortedFunds = _.sortBy(funds, 'weight').reverse();

            //Creating only first fund with detailed information.
            let firstFund = sortedFunds[0],
                remainingFunds = sortedFunds.splice(1);
            assetItem(
                firstFund.name,
                firstFund.weight * 100,
                amount,
                firstFund.fund_descr
            ).appendTo(domInstruments);

            //Creating an aggregate of the remaining funds.
            let sumWeightRemainingFunds = _.sumBy(remainingFunds, function(x) {
                return x.weight;
            });
            if (sumWeightRemainingFunds > 0) {
                assetItem(
                    'Altri fondi ed ETF',
                    sumWeightRemainingFunds * 100,
                    amount,
                    'Visibili solo ai clienti'
                ).appendTo(domInstruments);
            }
        });
    };

    const refreshIntruments = function(
        portfolio,
        amount,
        domAssets,
        assetSubassetDescr
    ) {
        let assetClasses = percByAssetClass(portfolio);
        let subAssetClasses = percBySubAssetClass(portfolio);
        let assetClassesSorted = assetClassesSortedByRank(assetClasses);

        $(domAssets).empty();

        _.forEach(assetClassesSorted, function(value) {
            let assetName = value[0];
            let assetWeight = value[1];
            let colorHex =
                assetClassColorWeightMap[assetName.replace(/ /g, '_')][0];
            let prefix = 'subassets';
            let assetDescr = _.find(assetSubassetDescr, { label: assetName })
                .description;
            let domAsset = assetItem(
                assetName,
                assetWeight,
                amount,
                assetDescr,
                prefix,
                colorHex
            ).appendTo(domAssets);
            let domSubAssets = assetList(assetName, prefix).appendTo(domAsset);

            let relatedSubAssetClass = _.filter(portfolio, {
                asset_class: assetName,
            });
            let relatedAssets = _.groupBy(
                relatedSubAssetClass,
                'sub_asset_class'
            );
            let newAssets = [];
            for (let prop in relatedAssets) {
                let elems = relatedAssets[prop];
                let sumElems = _.sumBy(elems, 'weight');
                newAssets.push([prop, relatedAssets[prop], sumElems]);
            }
            let indexTotalWeight = 2;
            let sortedRelatedAssets = _.sortBy(newAssets, function(x) {
                return x[indexTotalWeight];
            }).reverse();
            createRelatedAssets(
                sortedRelatedAssets,
                subAssetClasses,
                colorHex,
                amount,
                domSubAssets
            );
        });
        // console.log('refreshInstrument');
        // $('[data-toggle="popover"]').popover({'html': true, trigger: 'hover'});
    };

    const drawPerformanceChart = function(lineChartData) {
        let data = lineChartData['data'];
        let dates = lineChartData['dates'];

        // TODO Temporary
        let chartData = [];
        for (let i = 0; i < data.length; i++)
            chartData.push({ date: dates[i], value: data[i] });

        let chart = AmCharts.makeChart('echart_line', {
            accessible: true,
            autoMarginOffset: 0,
            autoResize: true,
            borderAlpha: 0,
            type: 'serial',
            language: 'it',
            marginRight: 20,
            marginBottom: 10,
            marginTop: 10,
            marginLeft: 0,
            hideCredits: true,
            theme: 'light',
            fontFamily: 'Ubuntu',
            fontSize: 12,
            color: '#616161',
            mouseWheelZoomEnabled: true,
            dataDateFormat: 'DD-MM-YYYY',
            graphs: [
                {
                    id: 'g1',
                    bullet: 'round',
                    bulletBorderAlpha: 1,
                    bulletColor: '#FFFFFF',
                    bulletSize: 5,
                    lineColor: '#22A189',
                    hideBulletsCount: 50,
                    lineThickness: 2,
                    useLineColorForBulletBorder: true,
                    valueField: 'value',
                    balloon: {
                        adjustBorderColor: true,
                        borderThickness: 1.5,
                        borderColor: '#4eb3a0',
                        color: '#ffffff',
                        cornerRadius: 1,
                        fillColor: '#22A189',
                        fillAlpha: 1,
                        horizontalPadding: 10,
                        shadowColor: '#ffffff',
                        verticalPadding: 5,
                    },
                    balloonText:
                        "<span style='font-size:20px;'>[[value]]</span><br><span style='font-size:11px;'>[[date]]</span>",
                },
            ],
            chartScrollbar: {
                graph: 'g1',
                oppositeAxis: false,
                offset: 0,
                scrollbarHeight: 80,
                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: '#888888',
                graphFillAlpha: 0,
                graphLineAlpha: 0.5,
                selectedGraphFillAlpha: 0,
                selectedGraphLineAlpha: 1,
                autoGridCount: true,
                color: '#AAAAAA',
            },
            chartCursor: {
                animationDuration: 0.1,
                categoryBalloonDateFormat: 'DD MMM YYYY',
                pan: true,
                categoryLineEnabled: true,
                categoryLineBalloonEnabled: true,
                cursorPosition: 'middle',
                valueLineEnabled: false,
                valueLineBalloonEnabled: true,
                color: '#616161',
                cursorAlpha: 1,
                cursorColor: '#e1e1e1',
                leaveCursor: true,
                limitToGraph: 'g1',
                valueZoomable: true,
            },
            categoryField: 'date',
            categoryAxis: {
                axisAlpha: 1,
                axisColor: '#e1e1e1',
                axisThickness: 1,
                boldLabels: false,
                dashLength: 1,
                equalSpacing: false,
                gridAlpha: 0,
                gridThickness: 0,
                ignoreAxisWidth: false,
                inside: false,
                minorGridEnabled: true,
                parseDates: true,
                position: 'bottom',
            },
            marginsUpdated: true,
            valueAxes: [
                {
                    id: 'v1',
                    axisAlpha: 1,
                    position: 'left',
                    axisColor: '#e1e1e1',
                    axisThickness: 1,
                    boldLabels: false,
                    dashLength: 1,
                    equalSpacing: false,
                    gridAlpha: 0,
                    gridThickness: 0,
                    ignoreAxisWidth: false,
                    inside: false,
                    minorGridEnabled: true,
                    parseDates: true,
                },
            ],
            numberFormatter: {
                precision: -2,
                decimalSeparator: ',',
                thousandsSeparator: '.',
            },
            responsive: {
                enabled: true,
            },
            svgIcons: true,
            pathToImages: AmCharts_path,
        });
        chart.dataProvider = chartData;
        chart.validateData();

        function zoomChart() {
            //chart.zoomToIndexes(chart.dataProvider.length - 40, chart.dataProvider.length - 1);
            chart.zoomToIndexes(0, chart.dataProvider.length - 1);
        }

        chart.addListener('rendered', zoomChart);
        zoomChart();
    };

    const drawPerformancePerYearChart = function(barChartData) {
        // TODO Temporary
        let data = [];
        for (let i = 0; i < barChartData.length; i++)
            data.push({
                category: barChartData[i]['label'],
                'column-1': barChartData[i]['value'],
            });

        AmCharts.makeChart('perf_chart', {
            accessible: true,
            autoMarginOffset: 0,
            autoMargins: false,
            autoResize: true,
            borderAlpha: 0,
            categoryField: 'category',
            color: '#616161',
            columnWidth: 10,
            dataDateFormat: 'DD-MM-YYYY',
            fontFamily: 'Ubuntu',
            fontSize: 12,
            valueField: 'total',
            labelText: "<span style='font-size:20px;'>[[value]]</span>",
            hideCredits: true,
            language: 'it',
            marginRight: 20,
            marginBottom: 40,
            marginTop: 20,
            marginLeft: 20,
            startDuration: 1,
            theme: 'light',
            type: 'serial',
            categoryAxis: {
                axisAlpha: 1,
                axisColor: '#e1e1e1',
                axisThickness: 1,
                boldLabels: false,
                dashLength: 1,
                gridAlpha: 0,
                gridThickness: 0,
                offset: 0,
                position: 'bottom',
            },
            valueAxes: [
                {
                    axisAlpha: 0,
                    fixedColumnWidth: 150,
                    gridAlpha: 0,
                    id: 'ValueAxis-1',
                    labelsEnabled: false,
                    offset: 0,
                    autoGridCount: false,
                },
            ],
            graphs: [
                {
                    fillAlphas: 1,
                    id: 'AmGraph-2',
                    showBalloon: false,
                    type: 'column',
                    color: '#616161',
                    fontFamily: 'Ubuntu',
                    fontSize: 18,
                    labelText: '[[column-1]]',
                    valueField: 'column-1',
                    lineColor: '#22A189',
                    hideBulletsCount: 50,
                    fixedColumnWidth: 50,
                    showAllValueLabels: true,
                },
            ],
            dataProvider: data,
            svgIcons: true,
            pathToImages: AmCharts_path,
        });
    };

    const setupPie = function(currentPortfolio) {
        pieContent = pieChartContent(currentPortfolio);

        let formatLabel = function(label) {
            let newLabel = label;
            return newLabel.toUpperCase();
        };

        let innerText = function(pie, obj, color) {
            $('#pie-title')
                .text(formatLabel(obj.data.label))
                .css('color', color);
            $('#pie-perc')
                .text(Math.round(obj.data.value) + '%')
                .css('color', color);
        };

        let closeBtn = $('#backtoAssetClass');

        closeBtn.on('click', function(event) {
            $(this).hide();
            event.preventDefault();
            resetPie();
            $('.collapsed').click();
        });

        return new d3pie('#pieChart', {
            size: {
                canvasWidth: 340,
                canvasHeight: 340,
                pieInnerRadius: '63%',
                pieOuterRadius: '100%',
            },
            labels: {
                inner: {
                    format: 'none',
                    hideWhenLessThanPercentage: 1,
                },
                outer: {
                    format: 'none',
                    pieDistance: 32,
                },
                mainLabel: {
                    fontSize: 18,
                    font: 'Ubuntu',
                },
                percentage: {
                    fontSize: 14,
                    color: '#ffffff',
                    decimalPlaces: 0,
                },
                value: {
                    color: '#adadad',
                    fontSize: 18,
                },
                lines: {
                    enabled: true,
                },
                truncation: {
                    enabled: false,
                },
            },
            data: {
                content: pieContent,
            },
            effects: {
                load: {
                    effect: 'none',
                    speed: 500,
                },
                pullOutSegmentOnClick: {
                    effect: 'linear',
                    speed: 500,
                    size: 6,
                },
            },
            callbacks: {
                onClickSegment: function(obj) {
                    let openSegmentIndex = obj.index;
                    let openSegmentColor = pieContent[obj.index].color;
                    let assetClassName = obj.data.label;
                    if (!obj.data.hasOwnProperty('subasset')) {
                        let assetClassObjects = _.filter(
                            currentPortfolio,
                            function(x) {
                                return x.asset_class === assetClassName;
                            }
                        );
                        let subAssets = _.groupBy(
                            assetClassObjects,
                            'sub_asset_class'
                        );

                        let subAssetsWithWeight = [];
                        for (let prop in subAssets) {
                            let elems = subAssets[prop];
                            let sumElems = _.sumBy(elems, 'weight');
                            subAssetsWithWeight.push([
                                prop,
                                subAssets[prop],
                                sumElems,
                            ]);
                        }
                        let sortedSubAssetsWithWeight = _.sortBy(
                            subAssetsWithWeight,
                            function(x) {
                                return x[2];
                            }
                        ).reverse();
                        let newSegmentContent = [];
                        let initialTintLevel = 20;
                        for (let prop in sortedSubAssetsWithWeight) {
                            newSegmentContent.push({
                                label: sortedSubAssetsWithWeight[prop][0],
                                value: sortedSubAssetsWithWeight[prop][2] * 100,
                                color: lightenDarkenColor(
                                    openSegmentColor,
                                    initialTintLevel
                                ),
                                subasset: true,
                                asset: obj.data.label,
                                assetPerc: obj.data.value,
                            });
                            initialTintLevel += 20;
                        }

                        let before = _.take(pieContent, openSegmentIndex);
                        let after = _.slice(pieContent, openSegmentIndex + 1);
                        let newPieContent = _.concat(
                            before,
                            newSegmentContent,
                            after
                        );

                        $('#pieChart').fadeTo(250, 0.5, function() {
                            pie.updateProp('data.content', newPieContent);
                            $('#pieChart').fadeTo(300, 1);
                        });
                        let l = obj.data.label.replace(/ /g, '_');
                        let color = assetClassColorWeightMap[l][0];
                        innerText(pie, obj, color);
                        pieContent = newPieContent;
                    } else {
                        innerText(pie, obj, obj.data.color);
                    }

                    closeBtn.show();

                    let elemName =
                        '#' +
                        normalizeName(obj.data.label.toLowerCase()) +
                        '>div';

                    if (obj.data.hasOwnProperty('subasset')) {
                        let assetName =
                            '#' + normalizeName(obj.data.asset) + '>div';
                        if (!$(assetName).hasClass('collapsed'))
                            $(assetName).click();
                        $(elemName).click();
                    } else if (!$(elemName).hasClass('collapsed'))
                        $(elemName).click();
                },
                onMouseoverSegment: function(obj) {
                    innerText(pie, obj, obj.data.color);
                },
            },
        });
    };

    const setupAmountBox = function() {
        let amountTarget = $('#amount');
        // Add format to investment amount input
        amountTarget.number(true, '.', ' ', '.');
        // console.log('amountTarget ', amountTarget);

        amountTarget.on('keyup', function(e) {
            let keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
            let amountVal = amountTarget.val();
            let amountComparator = document.getElementById('amountComparator');
            amountComparator.dataset.amount = amountVal;
            setTimeout(
                // prevents toooo many ajax call on typing
                () => {
                    setupComparatoreCostiFn(); // FIXME: WTF !!!
                    refreshIntruments(
                        currentPortolio,
                        parseInt(amountVal.replace(/\./g, '')) / 100,
                        '#assets',
                        categories
                    ); // TODO: Does this works?
                    updateCostTable();
                    redrawCharts();
                },
                1200
            );
        });
    };

    const resetPie = function() {
        if (pie) pie.destroy();
        // {# $('#backtoAssetClass').remove();#}
        pie = setupPie(currentPortolio);
        $('#pie-title')
            .text("Seleziona un'area")
            .css('color', '#a1a1a1');
        // {# $('#pie-title').text('');#}
        $('#pie-perc').text('');
    };

    const redrawPortfolio = function() {
        let amount = $('#amount').val();

        try {
            amount = parseInt(amount) / 100;
        } catch (err) {
            amount = 10000 / 100;
        }

        resetPie();
        refreshIntruments(currentPortolio, amount, '#assets', categories);
    };

    const fmt = function(x) {
        // x is not always a number
        let x_value = parseFloat(x);
        if (x_value === -0.0) return '0%';
        if (x_value < 0) return x.toString().replace('.', ',') + '%';
        return '+' + x.toString().replace('.', ',') + '%';
    };

    const perc_fmt = function(x) {
        x = x.toString().replace(',', '.');
        let x_value = parseFloat(x);
        if (x_value === -0.0) return '0%';
        return (
            Number(x)
                .toFixed(2)
                .toString()
                .replace('.', ',') + '%'
        );
    };

    const eur_fmt = function(x) {
        // console.log('€ X ', number(x, 0, ',', '.'));
        return '€ ' + number(x, 0, ',', '.');
    };

    const calc_total_fee = function() {
        let amount = parseFloat($('#amount').val());
        let fee = '0,60';
        let commissione_wm = DATA_CHOSEN_CORE.commissione_wm;

        if (commissione_wm !== '-1') {
            // STRING
            fee = commissione_wm;
            if (
                $('#commissioni_perc').val() != 'undefined' ||
                $('#commissioni_perc').val() != null
            ) {
                fee = $('#commissioni_perc').val();
            }
        }
        let perc = parseFloat(fee.replace(',', '.'));
        return Math.round((perc * amount) / 100);
    };

    const calc_total_cost = function() {
        let perc = parseFloat(
            $('#costi_perc')
                .text()
                .replace(',', '.')
        );
        let amount = parseFloat($('#amount').val());
        return Math.round((perc * amount) / 100);
    };

    const calc_total_sum_perc = function calc_total_sum_perc() {
        let costi = parseFloat(
            $('#costi_perc')
                .text()
                .replace(',', '.')
        );
        let commissioni = parseFloat(
            $('#commissioni_perc')
                .text()
                .replace(',', '.')
        );
        return Number(costi + commissioni).toFixed(2);
    };

    const calc_total_sum = function calc_total_sum() {
        let commissioni = parseFloat(
            $('#commissioni_tot')
                .text()
                .replace('€', '')
                .replace('.', '')
        );
        let costi = parseFloat(
            $('#costi_tot')
                .text()
                .replace('€', '')
                .replace('.', '')
        );
        return costi + commissioni;
    };

    // if wealth_management . qualcosa django - spostato in doc.READY

    const updateCostTable = function updateCostTable(wm_commissione_input) {
        $('#costi_tot').text(eur_fmt(calc_total_cost()));
        let amount = parseFloat($('#amount').val());
        let fee = '0,60';
        let commissione_wm = DATA_CHOSEN_CORE.commissione_wm;

        if (commissione_wm !== '-1') {
            // STRING
            fee = commissione_wm;
        }
        if (
            wm_commissione_input != 'undefined' &&
            wm_commissione_input != null
        ) {
            $('#commissioni_perc').text(perc_fmt(wm_commissione_input));
            $('#commissioni_perc').val(wm_commissione_input.replace(',', '.'));
            $('#_wm_commissione').val($('#commissioni_perc').val());
        } else if (commissione_wm != '-1') {
            $('#commissioni_perc').val(fee.replace(',', '.'));
            $('#commissioni_perc').text(perc_fmt(fee));
            $('#_wm_commissione').val($('#commissioni_perc').val());
        } else {
            $('#commissioni_perc').text(perc_fmt(fee));
        }
        $('#commissioni_tot').text(eur_fmt(calc_total_fee()));
        $('#tot_perc').text(perc_fmt(calc_total_sum_perc()));
        $('#tot').text(eur_fmt(calc_total_sum()));
    };

    const refreshPortfolio = function() {
        let risk = $('#portfolio-core').val();
        let amount = $('#amount').val(); // TODO consoleDebug

        // console.log(`r: ${risk}, a: ${amount}, ms: ${DATA_CHOSEN_CORE.reccomended_risk}`);

        $.ajax({
            url: DATA_CHOSEN_CORE.onboarding_refresh_portfolio,
            method: 'POST',
            data: { r: risk, a: amount, ms: DATA_CHOSEN_CORE.reccomended_risk },
        }).done(function(pfolio) {
            $('#portfolioDescrFstSect').text(pfolio.description.rationale);
            $('#portfolioDescrSndSectTitle').text(
                pfolio.description.features_title
            );
            $('#portfolioDescrSndSectBody').text(pfolio.description.features);
            $('#portfolioDescrTrdSect').text(pfolio.description.content);
            $('#annualReturn').text(fmt(pfolio.performance.annual_return));
            $('#performanceYearToDate').text(
                pfolio.performance.per_year[
                    pfolio.performance.per_year.length - 1
                ].label
            );
            $('#performanceYearToDateVal').text(
                pfolio.performance.per_year[
                    pfolio.performance.per_year.length - 1
                ].value + '%'
            );
            $('#performanceWorstYear').text(pfolio.performance.worst.year);
            $('#performanceWorstYearVal').text(
                fmt(pfolio.performance.worst.val)
            );
            $('#costi_perc').text(perc_fmt(pfolio.oneri));

            updateCostTable();

            currentPortolio = pfolio.assets;
            categories = pfolio.categories;
            drawPerformanceChart(pfolio.performance.chart);
            drawPerformancePerYearChart(pfolio.performance.per_year);
            redrawPortfolio();
        });
    };

    const redrawCharts = function() {
        let risk = $('#portfolio-core').val();
        let amount = $('#amount').val();
        $.ajax({
            url: DATA_CHOSEN_CORE.onboarding_refresh_charts,
            method: 'POST',
            data: { a: amount, r: risk },
        }).done(function(chartData) {
            $('#annualReturn').text(fmt(chartData.annual_return));
            $('#performanceBestYear').text(chartData.best.year);
            $('#performanceBestYearVal').text(fmt(chartData.best.val));
            $('#performanceWorstYear').text(chartData.worst.year);
            $('#performanceWorstYearVal').text(fmt(chartData.worst.val));
            $('#performanceYearToDateVal').text(
                chartData.per_year[chartData.per_year.length - 1].value + '%'
            );
            drawPerformanceChart(chartData.chart);
            drawPerformancePerYearChart(chartData.per_year);
        });
    };

    // CARDS
    let CARDS = {
        stepProposta: $('#stepProposta'),
        stepContent: $('.step_content'),
        mainCard: $('.main-card'),
        stepBar: $('.step_bar'),
        goOn: $('#go-on'),
        marginAmount: 2.5,
        baseColor: $('body'), //$('.progress-container'),
        colorDarkAmount: 8,
        shadowSpread: 8,
        coreCode: 0,
    };

    const subtractLight = function(color, amount) {
        return (parseInt(color) - amount).toString();
    };

    const darken = (color, amount) => {
        amount = parseInt((255 * amount) / 100);
        color = color.split('-');
        let [r, g, b] = [...color];
        // console.log('color' + color + ':' + r + ',' + g + ',' + b);
        return `rgb(${subtractLight(r, amount)},${subtractLight(
            g,
            amount
        )},${subtractLight(b, amount)})`;
    };

    const countSteps = () => CARDS.stepContent.length;

    const appendInactiveCards = function() {
        let baseColor = CARDS.baseColor
            .css('background-color')
            .match(/\d+/g)
            .join('-');
        // let starcolor = '177-72-121';
        for (let step = 1; step < countSteps(); step++) {
            let color = darken(baseColor, step * CARDS.colorDarkAmount);
            CARDS.mainCard
                .parent()
                .prepend(
                    `<div class="step_card inactive-${step} " style="margin: 0 ${step *
                        CARDS.marginAmount}%; background-color: ${color};"></div>`
                );
            // get inactive card height
            CARDS.shadowSpread = $(".step_card[class*='inactive-']")
                .first()
                .height();
        }
    };
    const animateStepBar = function(step) {
        let w = 100 / (countSteps() / step);
        CARDS.stepBar.animate({ width: w + '%' });
    };

    const fillDataStepCard = function(goal, step) {
        DATA_STEP_CARD[step] = goal;
    };

    const beautifyCore = function(core) {
        if (core.endsWith('.0')) {
            return core[0];
        } else {
            return core.replace('.', ',');
        }
    };

    const callForCore = function() {
        $('.card-container').hide();
        $('.steps__title').hide();
        CARDS.goOn.addClass('active');
        var query_string =
            '?age=' +
            DATA_STEP_CARD['2'] +
            '&goal=' +
            DATA_STEP_CARD['1'] +
            '&risk_tol=' +
            DATA_STEP_CARD[3];
        $.ajax({
            url: DATA_CHOSEN_CORE.get_core + query_string,
            method: 'GET',
        }).done(function(response) {
            setTimeout(() => {
                CARDS.goOn.removeClass('active');
                CARDS.stepProposta.hide();
                $('#chosenCore').show();
                CARDS.coreCode = response.portfolio;
                DATA_CHOSEN_CORE['core'] = CARDS.coreCode;
                $('#portfolio-core').val(parseFloat(CARDS.coreCode));
                $('#core_description').text(response.description);
                $('#selected-core').html(beautifyCore(CARDS.coreCode));
                setupAmountBox();
                refreshPortfolio();
            }, 500);
        });
    };

    const toNextStep = function(target) {
        let value = $(target).data('goal');
        let currentItem = $(target).closest('.step_content');
        let step = currentItem.data('step');
        let nextItem = currentItem.next('.step_content');
        CARDS.stepContent.last('.step_content').addClass('last_step');
        let inputBox = $(target).siblings('input');
        if (inputBox.length > 0) {
            inputBox.parsley().validate();
            if (inputBox.parsley().isValid() === false) {
                return;
            }
            value = inputBox.val();
        }

        if (step == 1) {
            $('#goal_value').val($(target).text());
        } else if (step == 2) {
            $('#age_value').val($(target).text());
        } else {
            $('#risk_tol_value').val($(target).text());
        }

        fillDataStepCard(value, step);

        animateStepBar(step);
        currentItem
            .removeClass('active')
            .animate({ opacity: 0, height: 0 }, 1000);
        nextItem.attr('style', '').addClass('active');
        $('.inactive-' + step).animate(
            { height: 0, marginLeft: 0, marginRight: 0 },
            100
        );

        if (currentItem.hasClass('last_step')) {
            callForCore();
        }
    };
    const toPrevStep = function(target) {
        let currentItem = $(target).closest('.step_content');
        let step = currentItem.data('step');
        let prevItem = currentItem.prev('.step_content');

        fillDataStepCard(null, step);
        animateStepBar(step - 2);

        currentItem
            .removeClass('active')
            .animate({ opacity: 0, height: 0 }, 1000);

        prevItem.attr('style', '').addClass('active');

        $('.inactive-' + (step - 1)).animate(
            {
                height: CARDS.shadowSpread + 'px',
                opacity: 1,
                marginLeft: (step - 1) * CARDS.marginAmount + '%',
                marginRight: (step - 1) * CARDS.marginAmount + '%',
            },
            100
        );
    };

    // READY?
    $(document).ready(function() {
        // CARDS

        // add fake BackCards
        appendInactiveCards();
        animateStepBar(0);

        CARDS.stepProposta.on('click', 'button', function() {
            toNextStep(this);
        });
        CARDS.stepProposta.on('click', '.back', function() {
            toPrevStep(this);
        });

        // OLD CODE
        // Apply validation to sign up form
        $('#signup-form').parsley(PARSLEY_CONFIG);

        $('#password').on('click focus', function(event) {
            event.preventDefault();
            $('.password-notice').css('display', 'block');
        });

        // Validation function
        let registrazioneForm = $('#signup-form');
        registrazioneForm.submit(function() {
            //$(this).parsley();
            registrazioneForm.parsley();
            $('#signup-form > button')
                .prop('disabled', true)
                .button('loading');
            return true;
        });

        //p2
        let $profileRisk = $('.onboarding-exp-investimento__profile__risk');
        $profileRisk.text(
            profileRiskValueToString($profileRisk.attr('data-risk'))
        );

        // Show sign-up and login form on mobile
        $('#show-signup-form').on('click', function() {
            $('.onboarding-exp-investimento__registration').addClass(
                'onboarding-exp-investimento__registration--force-visible'
            );
        });

        // Hide sign-up and login form on mobile
        $('#hide-signup-form').on('click', function() {
            $('.onboarding-exp-investimento__registration').removeClass(
                'onboarding-exp-investimento__registration--force-visible'
            );
        });

        // p3

        $.ajaxSetup({
            beforeSend: function(xhr) {
                xhr.setRequestHeader(
                    'X-CSRFToken',
                    DATA_CHOSEN_CORE.csrf_token
                );
            },
        });

        // p3.b

        $('ul.tabs-exp li').click(function() {
            let tabId = $(this).attr('data-tab-exp');
            let currentTabPane = $(this).closest('.tab-pane');

            currentTabPane.find('ul.tabs-exp li').removeClass('current');
            currentTabPane.find('.tab-exp-content').removeClass('current');

            $(this).addClass('current');
            $('#' + tabId).addClass('current');
            resetPie();
        });

        // in refreshIntruments()
        $('[data-toggle="popover"]')
            .off('hover')
            .popover(poperConfig);

        setupComparatoreCostiFn();

        $(document).on(
            'click',
            '#questionOptions input[type="checkbox"]',
            function() {
                $(this)
                    .parent()
                    .toggleClass('answer-checked');
            }
        );

        $(document).popover(poperConfig);
    }); //end ready

    stickybits('.sticky-content', {
        // add and remove sticky CSS Classes as: .js-is-sticky and .js-is-stuck
        useStickyClasses: true,
        stickyBitStickyOffset: 90,
    });
} // END CHECK onbPrtId
