import { qs } from '../utils';

const $ = require('jquery');

// Bootstrap dependencies
window.$ = window.jQuery = require('jquery'); // required for bootstrap
require('popper.js'); // required for tooltip, popup...
require('bootstrap');

if (qs('#onb-informativa-contratto')) {
    // TODO : clean consoleDebug

    // console.log('TPL-onb-inform_contr_euclid');
    // console.log('DATA_INFO: ', DATA_INFO);

    // 0.1 Conf
    const poperConfig = {
        html: true,
        trigger: 'hover', // click, hover, focus
        selector: '[data-toggle="popover"]',
        container: 'body',
    };

    // input validation
    // Validation configuration
    var PARSLEY_CONFIG = {
        errorClass: 'has-error',
        errorsWrapper: "<span class='help-block'></span>",
        errorTemplate: "<span style='color: #e74c3c;'></span>",
    };

    // if (typeof dataLayer !== 'undefined') {
    //     dataLayer.push({
    //         event: 'registrazione',
    //         eventCategory: 'Onboarding',
    //         eventAction: 'Contratto Euclidea',
    //     });
    // }

    // 0.3 VARIABILI ?
    const promo = $('#promo');
    const promoName = $('#promo_name');
    const promoDescription = $('#promo_description');
    const promoError = $('#promo_error');
    const registrazioneForm = $('#informativa');

    // READY?

    $(document).ready(function() {
        $('#informativa').parsley(PARSLEY_CONFIG);

        $('[data-toggle="popover"]')
            .off('hover')
            .popover(poperConfig);
        $(document).popover(poperConfig);

        // Validation function
        registrazioneForm.parsley(PARSLEY_CONFIG);
        registrazioneForm.submit(function() {
            // $("#subBtn").html(`<img src="${DATA_INFO.imgPath}">`);
            // $("#subBtn").html(`<img src="/static/images/icons/rolling_white.svg">`);
            $('#subBtn').addClass('active');
            return true;
        });

        // eslint-disable-next-line no-unused-vars
        promo.on('change', function(event) {
            if (this.value.length < 12) {
                promoName.hide();
                promoDescription.hide();
                promoError.hide();
            } else {
                promo.trigger('keyup');
            }
        });

        promo.on('keyup', function(event) {
            event.preventDefault();
            if (this.value.length == 12) {
                $.ajaxSetup({
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader(
                            'X-CSRFToken',
                            DATA_INFO.csrf_token
                        );
                    },
                });

                $.post('/onboarding/cp/', { code: this.value }, function(
                    response
                ) {
                    if (response.exists) {
                        promoName.html('<h6>' + response.name + '</h6>');
                        var description = response.description;
                        if (response.link) {
                            description +=
                                '<br><a target=_blank href="' +
                                response.link +
                                '">Regolamento completo</a>';
                        }
                        promoDescription.html(description);
                        promoName.show();
                        promoDescription.show();
                        promoError.hide();
                    } else {
                        promoError.text('Codice non valido');
                        promoName.hide();
                        promoDescription.hide();
                        promoError.show();
                    }
                });
            } else {
                promoName.hide();
                promoDescription.hide();
                promoError.hide();
            }
            this.value = this.value.toUpperCase();
        });

        if (promo.val() && promo.val().length == 12) {
            promo.trigger('keyup');
        }
    }); //end ready
} // END CHECK onb-informativa-contratto
